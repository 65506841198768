import { useOktaAuth } from '@okta/okta-react'
import React, {useState, useEffect} from 'react'
import axios from 'axios'

const WebviewPage = () => {

	const { authState } = useOktaAuth()

	const [isLoadingAdsTxt, setIsLoadingAdsTxt] = useState(true)
	const [adsTxt, setAdsTxt] = useState(null)

	const [isLoadingSellersJson, setIsLoadingSellersJson] = useState(true)
	const [sellersJson, setSellersJson] = useState(null)

	useEffect(() => {
		// make call to api to load property config
		// make call to get ads.txt/app-ads.txt/sellers.json

		if (!authState.isAuthenticated) {
			// authState will start empty so don't clear stored auth here
			//localStorage.clear()
			console.log(`authState.isAuthenticated = false`)
		} else {

			(async () => {

				console.log(`authState.isAuthenticated = true`)

				let KONG_TOKEN = localStorage.getItem('kong_token')

				const adsTxtResponse = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/file?filename=ads.txt`,
					{
						headers: {
							Authorization: `Bearer ${KONG_TOKEN}`
						}
					})

				setAdsTxt(adsTxtResponse.data)
				setIsLoadingAdsTxt(false)

				const sellersJsonResponse = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/file?filename=sellers.json`,
					{
						headers: {
							Authorization: `Bearer ${KONG_TOKEN}`
						}
					})

				setSellersJson(sellersJsonResponse.data)
				setIsLoadingSellersJson(false)
			})()
		}

	}, [authState])

	const saveAdsTxt = async (e) => {

		e.preventDefault()

		setIsLoadingAdsTxt(true)

		let KONG_TOKEN = localStorage.getItem('kong_token')
		let OKTA_TOKEN = localStorage.getItem('okta_token')

		await axios.post(`${process.env.REACT_APP_KONG_API_URL}/file`,
			{
				filename: 'ads.txt',
				contents: adsTxt,
				token: OKTA_TOKEN,
			},
			{
				headers: {Authorization: `Bearer ${KONG_TOKEN}`},
			})

		await axios.post(`${process.env.REACT_APP_KONG_API_URL}/file`,
			{
				filename: 'app-ads.txt',
				contents: adsTxt,
				token: OKTA_TOKEN,
			},
			{
				headers: {Authorization: `Bearer ${KONG_TOKEN}`},
			})

		setIsLoadingAdsTxt(false)
	}

	const saveSellersJson = async (e) => {

		e.preventDefault()

		setIsLoadingSellersJson(true)

		let KONG_TOKEN = localStorage.getItem('kong_token')
		let OKTA_TOKEN = localStorage.getItem('okta_token')

		await axios.post(`${process.env.REACT_APP_KONG_API_URL}/file`,
			{
				filename: 'sellers.json',
				contents: sellersJson,
				token: OKTA_TOKEN,
			},
			{
				headers: {Authorization: `Bearer ${KONG_TOKEN}`},
			})

		setIsLoadingSellersJson(false)
	}

	if (!authState.isAuthenticated) return <div>RESTRICTED</div>

	return <div className="App section">

		<div className={'container'}>

			<h1 className={'title'}>
				Webview Settings
			</h1>

			<div className={'tile is-ancestor'}>

				<div className={'tile is-vertical is-6 is-parent'}>
					<form>
						<div className={'field'}>
							<label className={'label'}>ads.txt / app-ads.txt</label>
							<div className={`control ${isLoadingAdsTxt && 'is-loading'}`}>
							<textarea
								className={'textarea'}
								value={adsTxt}
								rows={10}
								disabled={isLoadingAdsTxt}
								onChange={(e) => {
									setAdsTxt(e.target.value)
								}}/>
							</div>
						</div>
						<div className="field is-grouped is-grouped-right">
							<div className="control">
								<button className="button is-link is-light" disabled={isLoadingAdsTxt}>Cancel</button>
							</div>
							<div className="control">
								<button className="button is-link" onClick={saveAdsTxt} disabled={isLoadingAdsTxt}>Save</button>
							</div>
						</div>
					</form>
				</div>

				<div className={'tile is-vertical is-6 is-parent'}>
					<form>
						<div className={'field'}>
							<label className={'label'}>sellers.json</label>
							<div className={`control ${isLoadingSellersJson && 'is-loading'}`}>
							<textarea
								className={'textarea'}
								value={sellersJson}
								rows={10}
								disabled={isLoadingSellersJson}
								onChange={(e) => {
									setSellersJson(e.target.value)
								}}/>
							</div>
						</div>
						<div className="field is-grouped is-grouped-right">
							<div className="control buttons">
								<button className="button is-link is-light" disabled={isLoadingSellersJson}>Cancel</button>
								<button className="button is-link" onClick={saveSellersJson} disabled={isLoadingSellersJson}>Save</button>
							</div>
						</div>
					</form>
				</div>

			</div>
		</div>

	</div>
}

export default WebviewPage
