import React, { useState, useEffect } from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DurationPicker from 'react-duration-picker'
import {Editor} from '@tinymce/tinymce-react'

import {faKey} from '@fortawesome/pro-solid-svg-icons/faKey'
import {faAndroid} from '@fortawesome/free-brands-svg-icons/faAndroid'
import {faGlobeAmericas} from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas'
import {faImage} from '@fortawesome/pro-solid-svg-icons/faImage'
import {faFingerprint} from '@fortawesome/pro-solid-svg-icons/faFingerprint'
import {faHotel} from '@fortawesome/pro-solid-svg-icons/faHotel'
import {faConciergeBell} from '@fortawesome/pro-solid-svg-icons/faConciergeBell'
import {faDollarSign} from '@fortawesome/pro-solid-svg-icons/faDollarSign'
import {faRoad} from '@fortawesome/pro-solid-svg-icons/faRoad'
import {faCity} from '@fortawesome/pro-solid-svg-icons/faCity'
import {faMapMarker} from '@fortawesome/pro-solid-svg-icons/faMapMarker'
import {faLink} from '@fortawesome/pro-solid-svg-icons/faLink'
import {faText} from '@fortawesome/pro-solid-svg-icons/faText'
import {faCalendarDay} from '@fortawesome/pro-solid-svg-icons/faCalendarDay'
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import {faPercentage} from '@fortawesome/pro-solid-svg-icons/faPercentage'
import {faPalette} from '@fortawesome/pro-solid-svg-icons/faPalette'
import {faNewspaper} from '@fortawesome/pro-solid-svg-icons/faNewspaper'

const PropertyPage = () => {

	let { propertyID } = useParams()

	const [newDevice, setNewDevice] = useState(null)
	const [testDevices, setTestDevices] = useState([])
	const [iconMenu, setIconMenu] = useState([])

	const data = require('../data/intelity-hotel')
	const {service_requests, carousel, ads, settings} = data
	const {address, coordinates, icon_menu, schedule, screen_schedule, test_devices, theme, vast_tags} = settings

	useEffect(() => {
		// make call to api to load property config
		// make call to get ads.txt/app-ads.txt/sellers.json
		setIconMenu(icon_menu)
		setTestDevices(test_devices)
	}, [])

	return <>

		{/* Settings */}
		<form>

			<section className={'section'}>

				{/* Breadcrumbs */}
				<nav className={'breadcrumb'}>
					<ul>
						<li><NavLink to={'/'}>Kong Admin</NavLink></li>
						<li><NavLink to={'/properties'}>Properties</NavLink></li>
						<li className={'is-active'}><NavLink to={'/properties/intelity-hotel'}>Kong Admin</NavLink></li>
					</ul>
				</nav>

				{/* Property Name */}
				<div className={'field'}>
					<label className={'label'}>Property Name</label>
					<div className={'control has-icons-left'}>
						<input className={'input is-medium'} type={'text'} placeholder={'Property Name'} defaultValue={data.name} />
						<FontAwesomeIcon icon={faHotel} fixedWidth transform={'shrink-8'} className={'icon is-medium is-left'}/>
					</div>
					<p className="help">This name is displayed to the user</p>
				</div>

				{/* id and client_key */}
				<div className={'field is-grouped is-grouped-multiline'}>
					<div className={'control is-expanded'}>
						<label className={'label'}>ID</label>
						<div className={'control has-icons-left'}>
							<input className={'input'} type={'text'} placeholder={'property-id'} value={data.id} readOnly />
							<FontAwesomeIcon icon={faFingerprint} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
						</div>
						<p className="help">This is a read-only value used internally</p>
					</div>

					<div className={'control is-expanded'}>
						<label className={'label'}>Client Key</label>
						<div className={'control has-icons-left'}>
							<input className={'input'} type={'text'} placeholder={'client-key'} defaultValue={data.client_key} />
							<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
						</div>
						<p className="help">This is the client key required to access the property</p>
					</div>
				</div>
			</section>

			<section className={'section'}>
				<h1 className={'title'}>
					<FontAwesomeIcon icon={faGlobeAmericas} fixedWidth className={'icon is-left is-medium'}/> Location
				</h1>

				{/* Property Address */}
				<div className={'field'}>
					<label className={'label'}>Street Address</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} placeholder={'Street Address'} defaultValue={address.street} />
						<FontAwesomeIcon icon={faRoad} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Property City, State, Zipcode */}
				<div className={'field is-grouped is-grouped-multiline'}>
					<div className={'control is-expanded'}>
						<label className={'label'}>City</label>
						<div className={'control has-icons-left'}>
							<input className={'input'} type={'text'} placeholder={'City'} defaultValue={address.city} />
							<FontAwesomeIcon icon={faCity} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
						</div>
					</div>
					<div className={'control is-expanded'}>
						<label className={'label'}>State</label>
						<div className={'select is-fullwidth'}>
							<select>
								<option>Test</option>
							</select>
						</div>
					</div>
					<div className={'control is-expanded'}>
						<label className={'label'}>Zipcode</label>
						<div className={'control is-expanded'}>
							<input className={'input'} type={'text'} placeholder={'Zipcode'} defaultValue={address.zipcode} />
						</div>
					</div>
				</div>

				{/* Latitude, Longitude */}
				<div className={'field is-grouped is-grouped-multiline'}>
					<div className={'control is-expanded'}>
						<label className={'label'}>Latitude</label>
						<div className={'control has-icons-left'}>
							<input className={'input'} type={'text'} placeholder={'Latitude'} defaultValue={coordinates[0]} />
							<FontAwesomeIcon icon={faMapMarker} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
						</div>
					</div>
					<div className={'control is-expanded'}>
						<label className={'label'}>Longitude</label>
						<div className={'control has-icons-left'}>
							<input className={'input'} type={'text'} placeholder={'Longitude'} defaultValue={coordinates[1]} />
							<FontAwesomeIcon icon={faMapMarker} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
						</div>
					</div>
				</div>
			</section>

			<section className={'section'}>

				<h1 className={'title'}>
					<FontAwesomeIcon icon={faAndroid} fixedWidth className={'icon is-left is-medium'}/> Android Client
				</h1>

				{/* Webview Link */}
				<div className={'field'}>
					<label className={'label'}>Webview URL</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.kong_webview_url} />
						<FontAwesomeIcon icon={faLink} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
					<p className="help">URL to load in Android client</p>
				</div>

				{/* Memory Restart Threshold */}
				<div className={'field'}>
					<label className={'label'}>Memory Restart Threshold</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.memory_restart_threshold} />
						<FontAwesomeIcon icon={faPercentage} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
					<p className="help">Restart the app if memory usage reaches this amount</p>
				</div>

				<div className={'field is-grouped is-grouped-multiline'}>

					{/* Idle Period */}
					<div className={'control is-expanded'}>
						<label className={'label'}>Idle period</label>
						<DurationPicker
							initialDuration={{
								hours: Math.floor(settings.idle_period_seconds / 3600),
								minutes: Math.floor(settings.idle_period_seconds / 60),
								seconds: settings.idle_period_seconds % 60,
							}}
						/>
						<p className="help">Period of inactivity before device is considered "idle"</p>
					</div>

					{/* Config Update Interval */}
					<div className={'control is-expanded'}>
						<label className={'label'}>Config Update Interval</label>
						<DurationPicker
							initialDuration={{
								hours: Math.floor(settings.kong_config_update_interval_seconds / 3600),
								minutes: Math.floor(settings.kong_config_update_interval_seconds / 60),
								seconds: settings.kong_config_update_interval_seconds % 60,
							}}
						/>
						<p className="help">How often to check for updated property config</p>
					</div>

					{/* Memory Restart Interval */}
					<div className={'control is-expanded'}>
						<label className={'label'}>Memory Restart Interval</label>
						<DurationPicker
							initialDuration={{
								hours: Math.floor(settings.memory_restart_interval_seconds / 3600),
								minutes: Math.floor(settings.memory_restart_interval_seconds / 60),
								seconds: settings.memory_restart_interval_seconds % 60,
							}}
						/>
						<p className="help">How often to check device memory</p>
					</div>

				</div>

				{testDevices && <div className={'field'}>
					<label className={'label'}>Test Devices</label>
					<div className="field has-addons">
						<div className="control">
							<input className="input" type="text" placeholder="Device ID" value={newDevice || ''} onChange={(e)=>{
								setNewDevice(e.target.value)
							}} />
						</div>
						<div className="control">
							<button className="button is-info" onClick={(e)=>{
								e.preventDefault()
								setTestDevices([...testDevices, newDevice])
								setNewDevice('')
							}} disabled={!newDevice || newDevice === ''}>
								Add Device
							</button>
						</div>
					</div>

					<div className={'field is-grouped is-grouped-multiline'}>
						{testDevices.length > 0
							? testDevices.map((serial) => {
								return <div className={'control'} key={serial}>
										<div className={'tags has-addons'}>
											<div className={'tag is-info'}>{serial}</div>
											<a className={'tag is-delete'}/>
										</div>
									</div>
							})
							:
							<div>None</div>
						}
					</div>
				</div>}

			</section>

			<section className={'section'}>

				<h1 className={'title'}>
					<FontAwesomeIcon icon={faPalette} fixedWidth className={'icon is-left is-medium'}/> UI Settings
				</h1>

				{/* Icon Menu */}
				<div className={'field'}>
					<label className={'label'}>Icon Menu</label>
					<div className={'control'}>
						<div className={'columns is-desktop'}>
						{iconMenu && iconMenu.map(({icon, title, link}) => {
							return <div className={'column'}>
								<div className="box">

											{/* Title */}
											<div className={'field'}>
												<label className={'label'}>Title</label>
												<div className={'control has-icons-left'}>
													<input className={'input is-small'} type={'text'} placeholder={'Title'} defaultValue={title} />
													<FontAwesomeIcon icon={faText} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
												</div>
											</div>

											{/* Icon */}
											<div className={'field'}>
												<label className={'label'}>Icon</label>
												<div className={'control has-icons-left'}>
													<input className={'input is-small'} type={'text'} placeholder={'Icon URL'} defaultValue={icon} />
													<FontAwesomeIcon icon={faImage} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
												</div>
											</div>

											{/* Link */}
											<div className={'field'}>
												<label className={'label'}>Link</label>
												<div className={'control has-icons-left'}>
													<input className={'input is-small'} type={'text'} placeholder={'Link URL'} defaultValue={link} />
													<FontAwesomeIcon icon={faLink} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
												</div>
											</div>

								</div>
							</div>
						})}
						</div>
					</div>
				</div>

				{/* Image */}
				<div className={'field'}>
					<label className={'label'}>Theme Image</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} placeholder={'client-key'} defaultValue={theme.image} />
						<FontAwesomeIcon icon={faImage} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Logo */}
				<div className={'field'}>
					<label className={'label'}>Logo</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} placeholder={'client-key'} defaultValue={theme.logo} />
						<FontAwesomeIcon icon={faImage} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Title */}
				<div className={'field'}>
					<label className={'label'}>Title</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} placeholder={'client-key'} defaultValue={theme.title} />
						<FontAwesomeIcon icon={faText} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Welcome Title */}
				<div className={'field'}>
					<label className={'label'}>Welcome Title</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} placeholder={'client-key'} defaultValue={theme.welcome_title} />
						<FontAwesomeIcon icon={faText} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Welcome Body */}
				<div className={'field'}>
					<label className={'label'}>Welcome Body</label>
					<div className={'control content box'}>
						<Editor
							init={{
								menubar: 'view insert format tools table',
							}}
							apiKey={'3i8ag6x1qoh26np9qnnwaowpy5nq7w87fmox9j6vnlep4qru'}
							plugins={'code image spellchecker lists table link hr linkchecker media nonbreaking visualblocks'}
							initialValue={theme.welcome_body}
							inline
							toolbar={[
								'undo redo | styleselect | bold italic | numlist bullist | alignleft aligncenter alignright | outdent indent',
								'media | link | table | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

							]}
						/>
					</div>
				</div>

			</section>

			<section className={'section'}>

				<h1 className={'title'}>
					<FontAwesomeIcon icon={faDollarSign} fixedWidth className={'icon is-left is-medium'}/> Ad Settings
				</h1>

				{/* VAST Tags */}
				<div className={'field'}>
					<label className={'label'}>VAST Tags</label>
					<div className={'control'}>
						{vast_tags && vast_tags.length >= 0 &&
						<ul>
							{vast_tags.map((vastTag) => {
								return <li key={vastTag}>{vastTag}</li>
							})}
						</ul>
						}
					</div>
				</div>

				{/* DFP ID */}
				<div className={'field'}>
					<label className={'label'}>DFP ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.dfp_id} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* InMobi Account ID */}
				<div className={'field'}>
					<label className={'label'}>InMobi Account ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.inmobi_account_id} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* MoPub Ad Unit ID */}
				<div className={'field'}>
					<label className={'label'}>MoPub Ad Unit ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.mopub_ad_unit_id} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Verizon Site ID */}
				<div className={'field'}>
					<label className={'label'}>Verizon Site ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.verizon_site_id} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

			</section>

			<section className={'section'}>

				<h1 className={'title'}>
					<FontAwesomeIcon icon={faNewspaper} fixedWidth className={'icon is-left is-medium'}/> Content Settings
				</h1>

				{/* Gamepix ID */}
				<div className={'field'}>
					<label className={'label'}>Gamepix ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.gamepix_id} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Viator Destination ID */}
				<div className={'field'}>
					<label className={'label'}>Viator Destination ID</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.viator_destid} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Survey URL */}
				<div className={'field'}>
					<label className={'label'}>Survey URL</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.survey_url} />
						<FontAwesomeIcon icon={faLink} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* SurveyMonkey Collector Hash */}
				<div className={'field'}>
					<label className={'label'}>SurveyMonkey Collector Hash</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.surveymonkey_collector_hash} />
						<FontAwesomeIcon icon={faKey} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* IAB Consent String */}
				<div className={'field'}>
					<label className={'label'}>IAB Consent String</label>
					<div className={'control'}>
						<textarea className={'textarea'} defaultValue={settings.iab_consent_string} />
					</div>
				</div>

				{/* Goldstar Day Range */}
				<div className={'field'}>
					<label className={'label'}>Goldstar Day Range</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={settings.goldstar_day_range} />
						<FontAwesomeIcon icon={faCalendarDay} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

			</section>

			<section className={'section'}>

				<h1 className={'title'}>
					<FontAwesomeIcon icon={faConciergeBell} fixedWidth className={'icon is-left is-medium'}/> Service Requests
				</h1>

				{/* Service Request Email */}
				<div className={'field'}>
					<label className={'label'}>Service Request Email</label>
					<div className={'control has-icons-left'}>
						<input className={'input'} type={'text'} defaultValue={service_requests && service_requests.email} />
						<FontAwesomeIcon icon={faEnvelope} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
					</div>
				</div>

				{/* Service Request Options */}
				<div className={'field'}>
					<label className={'label'}>Service Request Options</label>
					<div className={'control'}>
						<div className={'columns is-desktop'}>
						{service_requests && service_requests.options && service_requests.options.map(({id, description, type, max}) => {
							return <div className={'column'}>
								<div className="box">

									{/* ID */}
									<div className={'field'}>
										<label className={'label'}>ID</label>
										<div className={'control has-icons-left'}>
											<input className={'input is-small'} type={'text'} placeholder={'ID'} defaultValue={id} />
											<FontAwesomeIcon icon={faFingerprint} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
										</div>
									</div>

									{/* Description */}
									<div className={'field'}>
										<label className={'label'}>Description</label>
										<div className={'control has-icons-left'}>
											<input className={'input is-small'} type={'text'} placeholder={'Description'} defaultValue={description} />
											<FontAwesomeIcon icon={faText} fixedWidth transform={'shrink-8'} className={'icon is-small is-left'}/>
										</div>
									</div>

									{/* Type */}
									<div className={'field'}>
										<label className={'label'}>Type</label>
										<div className={'control is-expanded'}>
											<div className={'select is-fullwidth'}>
												<select>
													<option>{type}</option>
												</select>
											</div>
										</div>
									</div>

									{/* Max */}
									{type === 'item' &&
									<div className={'field'}>
										<label className={'label'}>Maximum Quantity</label>
										<div className={'control'}>
											<input className={'input is-small'} type={'text'}
											       placeholder={'Maximum Quantity'} defaultValue={max}/>
										</div>
									</div>
									}

								</div>
							</div>
						})}
						</div>
					</div>
				</div>

			</section>

			<nav className={'navbar is-fixed-bottom is-transparent'}>
				<div className={'container'}>
					<div id={'nav-menu'} className={'navbar-menu'}>
						<div className={'navbar-end'}>
							<div className={'navbar-item'}>
								<div className={'buttons are-large'}>
									<button className={'button is-light'}>Cancel</button>
									<button className={'button is-light is-primary'}>Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>

		</form>
		</>
}

export default PropertyPage
