import { useOktaAuth } from '@okta/okta-react'
import React, {useEffect, useState} from 'react'
import {
	NavLink,
} from 'react-router-dom'
import axios from 'axios'

const Header = () => {

	const { authState, authService } = useOktaAuth()

	const login = () => authService.login('/property')
	const logout = () => authService.logout('/')

	useEffect(() => {

		console.log(`getting authState...`)

		if (!authState.isAuthenticated) {
			// authState will start empty so don't clear stored auth here
			//localStorage.clear()
			console.log(`authState.isAuthenticated = false`)
		} else {
			(async () => {

				console.log(`authState.isAuthenticated = true`)

				localStorage.setItem('okta_token', authState.accessToken)
				console.log(`set okta_token to ${authState.accessToken}`)

				// now get JWT token from kong
				console.log(`getting kong token...`)
				const {data: kongToken} = await axios.post(`${process.env.REACT_APP_KONG_API_URL}/token`,
					{
						id: process.env.REACT_APP_KONG_PROPERTY_ID,
						client_key: process.env.REACT_APP_KONG_PROPERTY_CLIENT_KEY
					})

				localStorage.setItem('kong_token', kongToken)
				console.log(`set kong_token to ${kongToken}`)
			})()
		}
	}, [authState, authService])

	return (
		<nav className={'navbar is-primary'}>
			<div className={'container'}>

				<div className={'navbar-brand'}>
					<NavLink className={'navbar-item brand-text'} to={'/'}>
						Kong Admin
					</NavLink>

					<button className={'button navbar-burger is-primary'}>
						<span/>
						<span/>
						<span/>
					</button>
				</div>

				<div id={'nav-menu'} className={'navbar-menu'}>
					{authState.isAuthenticated && <div className={'navbar-start'}>
						<NavLink to={'/webview'} className={'navbar-item'}>Website</NavLink>
						<NavLink to={'/content'} className={'navbar-item'}>Content</NavLink>
						<NavLink to={'/cache'} className={'navbar-item'}>Cache</NavLink>
						<NavLink to={'/properties'} className={'navbar-item'}>Properties</NavLink>
						<NavLink to={'/devices'} className={'navbar-item'}>Devices</NavLink>
					</div>}
					<div className={'navbar-end'}>
						<div className={'navbar-item'}>
							<div className={'buttons'}>
								{!authState.isAuthenticated
									? <button className={'button is-light'} onClick={login}>Login</button>
									: <button className={'button is-light'} onClick={logout}>Logout</button>
								}
							</div>
						</div>
					</div>
				</div>

			</div>
		</nav>
	)
}

export default Header