import React from 'react'
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom'

import {Security, LoginCallback, SecureRoute} from '@okta/okta-react'

import 'typeface-lato'

import PropertyPage from './pages/PropertyPage'
import WebviewPage from './pages/webview'
import Header from './components/Header'

const CALLBACK_PATH = '/implicit/callback'

const oktaConfig = {
	clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
	issuer: process.env.REACT_APP_OKTA_ISSUER,
	redirectUri: `${window.location.origin}${CALLBACK_PATH}`,
	scopes: ['openid', 'profile', 'email', 'groups'],
	responseType: ['id_token', 'token'],
	pkce: false,
}

const App = () => {
	return (
		<Router>
			<Security {...oktaConfig}>
				<Header/>
				<div className={'container'}>
					<Route path={CALLBACK_PATH} component={LoginCallback}/>
					<Route exact path={'/'}>
						HOME
					</Route>
					<SecureRoute path={'/(property|properties)/'} component={PropertyPage} />
					<SecureRoute path={'/property/:propertyID'} component={PropertyPage} />
					<SecureRoute path={'/webview'} component={WebviewPage} />
				</div>
			</Security>
		</Router>
	)
}

export default App